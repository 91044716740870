import { Cart, CouponRedeem, Discount } from './cart'
import { CustomerAddress, Customer } from './customer'

export enum ShippingMethodCode {
  STANDARD = 'JAU_STD',
  EXPRESS = 'JAU_EXP',
  SAME_DAY = 'SMD',
}

export interface CheckoutDetails {
  deliveryType?: DeliveryType
  cartSummary: Cart
  customer?: Customer
  billingAddress?: CustomerAddress
  shippingAddress?: CustomerAddress
  shippingMethods?: ShippingMethod[]
  paymentSession?: string
  deliverynotes?: string
  isLoggedIn: boolean
  selectedShippingMethod?: string
  isCreditAvailable?: boolean
  shippingTier?: string
  paymentMethods?: PaymentMethod[]
  latitudePayUrl?: string
  discount?: Discount
  isDiscountCode?: boolean
  eligibleForInsurance?: boolean
  coupon?: CouponRedeem
  fullBasket?: boolean
  customerGroup?: string  
  cartContainsGiftCard?: boolean
}

export interface ReturnRequest {
  orderNumber: string
  products: {
    lineItemId: string
    quantity: number
    comment?: string
    shipmentState?: string
  }[]
  returnTrackingId?: string
  returnDate?: string
}

export interface UpdateContactDetailsRequest extends Record<string, string | undefined> {
  email: string
  firstName: string
  lastName: string
  company?: string
  addressLine1?: string
  addressLine2?: string
  suburb: string
  streetName?: string
  streetNumber?: string
  city: string
  state: string
  country: string
  latitude?: string
  longitude?: string
  placeId?: string
  postalCode: string
  phone: string
  address: string
  isManual: string
}

export interface ShippingMethod {
  code: ShippingMethodCode
  name: string
  price: number
  isAvailable: boolean
  condition?: string
  description?: string
}

export enum DeliveryType {
  DELIVERY = 'deliveryStore',
  PICKUP = 'clickCollect',
  WAREHOUSE = 'deliveryWarehouse',
}

export interface UpdateDeliveryTypeRequest {
  deliveryType: DeliveryType
}

export interface CheckoutPaymentRequest {
  deliveryCode?: string
  storeKey?: string
  forterCookie?: string
  storesPurchaseOrderNumber?: string
}

export interface SavePaymentMethodRequest {
  encryptedCardNumber?: string
  encryptedExpiryMonth?: string
  encryptedExpiryYear?: string
  encryptedSecurityCode?: string
  isDefault?: boolean
}

export interface EditPaymentMethodRequest extends SavePaymentMethodRequest {
  refId?: string
}

export interface DeletePaymentMethod {
  refId: string
}

export interface PaymentMethod {
  id: string
  title: string
}

export interface ApplyCreditResponse {
  orderNumber: string
}

export interface LatitudeCreateOrderRequest {
  gatewayReference: string
  merchantReference: string
  transactionReference: string
}

export interface RedeemGiftCardRequest {
  giftCardId: string
  pinCode?: string
}

export interface GetAddressSuggestionsResponse {
  suggestions: string[]
}
